import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "zone" ]

  immobilize() {
    if (this.hasZoneTarget) {
      this.zoneTargets.forEach((zoneTarget) => zoneTarget.inert = true)
    }
  }

  mobilize() {
    if (this.hasZoneTarget) {
      this.zoneTargets.forEach((zoneTarget) => zoneTarget.inert = false)
    }
  }
}
